<template>
  <div class="clearBox">
    <div class="center-btn">
      <el-button style="margin: 5px 30px" size="medium" plain @click="dialogVisible = true">显示设置</el-button>
    </div>

    <div>
      <div style="center-video" :style="{height:(carCheckList.length ===0 ? 'calc(80vh)':'100%')}">
        <!-- 显示设置diolog -->
        <el-dialog
                title="显示设置"
                :visible.sync="dialogVisible"
                width="450px"
                center>
            <span>
              <el-form ref="formInline" :model="formInline" label-width="120px">
                <el-form-item
                    label="最多显示屏数"
                    :rules="[
                    { required: true, message: '输入不能为空' },
                    { type: 'number', message: '必须为数字值'},
                    {min: 1, max: 4, required: true, message: '最多显示8屏', trigger: 'blur'},
                ]">
                  <el-input type="number" v-model.number="formInline.user"
                            onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                            placeholder="请输入纯数字" style="width: 120px"></el-input>
                </el-form-item>
              </el-form>
            </span>
          <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 显示设置diolog END -->
        <el-row class="videoCol">
          <el-col :span="12" style="padding: 10px" v-for="(item, idx) in allCheckUrl" :key="idx">
            <video-play :url='item[1]'></video-play>
            <div style="display: inline-block;line-height: 40px;height: 40px;margin-right: 10px">{{item.value}}</div>
            <span style="display: inline-block;text-align:right;margin:0px auto 0px auto;">
              <el-button size="mini" round>抓拍</el-button>
              <el-button size="mini" round>监听</el-button>
              <el-button size="mini" round>对讲</el-button>
              <el-button size="mini" round>音量</el-button>
              <el-button size="mini" round>色彩</el-button>
            </span>
            <el-select v-model="item.channel" placeholder="请选择视角" class="video_perspective" @change="paChange($event, item, idx)" style="width: 120px">
              <el-option v-for="(PaItem, PaIdx) in $enums.VideoPerspective.list" :key="PaIdx" :label="PaItem.label" :value="PaItem.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils/func'
import videoPlay from './videoPlay'
  export default {
    name: 'BusImg',
    components: {
      videoPlay,
    },
    props: {
      carList: {
        type: Array,
        default: function () {
          return []
        },
      },
    },
    data () {
      return {
        channel:2,
        videoUrl: [],
        oldVideoUrl: [],
        carCheckList: [],
        newCarCheckList: [],
        formInline: {
          user: 4,
        },
        carData: [],
        jsession: null,
        VideoNum: 4,
        dialogVisible: false,
        allCheckUrl: [],
        newAllCheckUrl: [],
      }
    },
    watch: {
      carList: {
        handler(newName, oldName) {
          //console.log(newName)
          this.$set(this,'carCheckList', newName)
          //console.log(this.carCheckList)
          this.checkClick()

        },
        immediate: true
      }
    },
    methods: {
      filterNode (value, data) {
        if (!value) return true
        // 只能筛选子子节点
        if (data.children) return false
        return data.label.indexOf(value) !== -1
      },
      // eslint-disable-next-line no-unused-vars
      async checkClick (value) {
        if (!this.unFlash()) {
          this.$notify({
            title: '系统警告',
            message: '系统检测到该网页未允许运行flash,这会导致视频无法播放,请在设置中打开flash',
            type: 'warning'
          });
          // this.$refs.tree.setCheckedNodes([])
          return
        }


        //通过勾选的节点长度判断视频窗口
        // if (this.carCheckList.length > this.VideoNum) {
        //   this.$message.error('视频个数不能超过' + this.VideoNum + '个')
        //   // this.$refs.tree.setCheckedNodes(this.newCarCheckList)
        //   return
        // }


        //获取勾选的节点
        // console.log(this.carCheckList)
        //this.carCheckList = []
        //this.carCheckList = this.$refs.tree.getCheckedNodes(true, false)
        if(this.carCheckList.length == this.VideoNum) {
          this.newCarCheckList = []
          this.newCarCheckList = this.carCheckList
          //console.log(this.newCarCheckList)
        }
        
        //获取勾选车辆的所有通道url
        this.allCheckUrl = await Promise.all(this.carCheckList.map(async e=>this.$api.getRtmpUrls({
          deviceId: e.device,
          channelList: [1,2,3,4,5,6],
          dataType: 1,
          streamType: 0,
          tenantId: e.tenantId,
        })))
        //console.log(this.allCheckUrl)
        this.videoUrl = this.allCheckUrl

        for(let j= 0;j<this.allCheckUrl.length; j++){
          let device = JSON.stringify(this.allCheckUrl[j][1])
          let deviceKey = device.substring(device.length -15, device.length -3)
          let td = utils.getStorage(deviceKey, 1)

          if(td === null){
            this.allCheckUrl[j].channel = 1
          }else{
            this.allCheckUrl[j].channel = td
            this.allCheckUrl[j][1] = this.allCheckUrl[j][td]
          }
          this.allCheckUrl[j].value = this.carCheckList[j].id
          this.allCheckUrl[j].tenantId = this.carCheckList[j].tenantId
          this.allCheckUrl[j].device = this.carCheckList[j].device
        }
        this.newAllCheckUrl = this.$_.cloneDeep(this.allCheckUrl)
      },

      submitForm () {
        if (this.formInline.user > 8) {
          this.$message.error('视频最多不能超过8个')
        } else if (this.formInline.user < 0) {
          this.$message.error('请输入1-8的整数')
        } else {
          this.$emit('editVideoviewNum', this.formInline.user)
          //console.log(this.VideoNum)
          return this.dialogVisible = false
        }
      },

      // 登录获取jsession
      async testAjax () {
        await this.$api.getJsession({})
          .then((res) => {
            this.jsession = res
          })
      },
      // 用户切换视角
        async paChange (value, item, idx) {
        if (value===1) {
          this.oldVideoUrl = await Promise.all(this.carCheckList.map(async e=>this.$api.getRtmpUrls({
            deviceId: item.device,
            channelList: [1,2,3,4,5,6],
            dataType: 1,
            streamType: 0,
            tenantId: item.tenantId,
          })))
          item[1] = this.oldVideoUrl[idx][1]
        }
        item[1] = item[value]
        this.allCheckUrl.splice(idx, 1, item)
        let deviceName = item[value].substring(item[value].length - 14, item[value].length -2)
        utils.setStorage(deviceName, value)
        //  console.log('取出存储paChange url: ' + utils.getStorage(deviceName, 1) + '-----' + value + 'url: ' + item[value])
      },
      // 检测网页是否启用了Flash
      unFlash(){
        let flashObj;
        if(typeof window.ActiveXObject != "undefined"){
          // eslint-disable-next-line no-undef
          flashObj = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
        }else{
          flashObj = navigator.plugins['Shockwave Flash'];
        }
        return flashObj ? true : false;
      },
    },

    async mounted () {
      // this.carData = await this.$api.getRealTimeCarInfoList({})
      // this.testAjax()
    },
  }
</script>

<style lang='sass' scoped>
  .clearBox
    height: 100%
    overflow: auto
    /deep/ .el-radio__label
      display: inline-block

    .radio-img
      width: 48px

    /deep/ .el-form--inline .el-form-item
      margin-right: 10px

    /deep/ .el-tree
      padding-bottom: 21px

    /deep/ .el-tree-node__content
      height: 30px

    /deep/ .el-input
      width: 100%

    /deep/ .el-tree-node__label
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    .carsVideoLeft
      max-width: 300px
      width: 300px
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      // padding: 25px 14px
      margin-right: 10px
      display: inline-block
      vertical-align: top
      overflow: auto

    .carsVideoRight
      width: calc(100% - 310px)
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      padding: 25px 14px
      display: inline-block
      vertical-align: top

      .setting
        text-align: right

    .center-btn
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      width: 100%

    .center-video
      margin-top: 20px


    .videoCol
      padding-top: 20px

      .videoAisle
        padding: 10px 0 20px
        display: inline-block
        vertical-align: middle
        font-size: 12px
        float: right

      .videoLicense
        padding-left: 15px
        display: inline-block
        vertical-align: -webkit-baseline-middle
        font-size: 12px
        line-height: 50px
  .video_perspective
    float: right
    width: 180px
    margin: 5px 0
  /deep/ .el-button--mini.is-round
    padding: 4px 12px
</style>
<style scoped>
  .carsVideoHeight {
    height: calc(80vh)
  }
</style>
